#toolbar {
  border-bottom: var(--border);
  padding: 15.4px;

  display: flex;
  justify-content: flex-start;
  overflow: auto;

  grid-column: 1 / 3;
  grid-row: 1 / 2;
}

.headersDropdown {
  background: transparent;
  color: var(--color-icons);
  font-weight: bold;
  margin-right: 8px;
  border-style: none;

  &:hover {
    color: var(--primary);
  }
}

.style-button {
  color: var(--color-icons);
  margin: 0 0.8rem;
  background: none;
  border-style: none;

  &:hover {
    color: var(--primary);
    cursor: pointer;
  }

  &.active {
    color: var(--primary);
  }
}

.block-styles,
.inline-styles {
  display: flex;
}
