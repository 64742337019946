.quick-access {
  padding: 0.8rem;

  display: flex;
  flex-direction: column;

  grid-column: 2 / 3;
  grid-row: 2 / 3;

  opacity: 0.3;

  &:hover {
    opacity: 0.8;
  }
}
