:root {
  --border: 1px solid var(--color-border);
  --san-serif: "Nunito", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  --serif: "Playfair Display", serif;

  --font: var(--san-serif);
  --modal-shadow: 0 0px 9px 0px #bfbfbf;
  --color-text: rgb(36, 35, 34);
  --color-text-secondary: rgb(36, 35, 34);
  --color-icons: rgb(77, 74, 71);
  --color-icons-light: #c0c0c0;
  --color-border: rgb(242, 237, 232);
  --primary: rgb(239, 155, 110);
  --primary-light: rgb(255, 233, 221);
  --color-code: rgb(36, 35, 34);
  --color-code-background: rgb(235, 235, 235);
}

[data-theme="noteworthy"] {
  --background: rgb(251, 251, 251);
  --font: var(--san-serif);
  --modal-shadow: 0 0px 9px 0px #bfbfbf;
  --color-icons: #565656;
  --toolbar-icons: rgb(77, 74, 71);
  --color-border: rgb(242, 237, 232);
  --color-icons-light: #c0c0c0;
  --color-text-secondary: rgb(36, 35, 34);
  --color-text: rgb(36, 35, 34);
  --primary: #1cb0f6;
  --primary-light: #bce9ff;
  --color-code: rgb(36, 35, 34);
  --color-code-background: rgb(235, 235, 235);
}