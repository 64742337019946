.authentication__header {
  color: white;
  text-align: center;
}

.authentication {
  text-align: center;
  justify-content: center;
}

.authentication form {
  margin-bottom: 1rem;
}